// layout.tsx
"use client";
import { useState } from "react";
import "./globals.css";
import { NextAuthProvider } from "./providers";
import Header from "components/header";
import Footer from "components/footer";
import { Inter } from "next/font/google";
import "./globals.css";

const inter = Inter({ subsets: ["latin"] });

export default function RootLayout({
	children,
}: Readonly<{
	children: React.ReactNode;
}>) {
	const [darkMode, setDarkMode] = useState(false);

	const toggleDarkMode = () => {
		console.log("toggle dark mode");
		setDarkMode(!darkMode);
	};

	return (
		<html lang="en" className={`${darkMode} ? "dark" : ""`}>
			<body className={`${inter.className} ${darkMode ? "dark" : ""}`}>
				<NextAuthProvider>
					<div className="min-h-screen light:bg-gradient-to-b light:from-gradient-start light:to-gradient-end light:text-light-text dark:from-dark-gradient-start dark:to-dark-gradient-end dark:text-dark-text">
						<Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
						<main className="flex-grow mt-10 justify-center align-center min-h-screen overflow-hidden">
							{children}
						</main>
						<Footer />
					</div>
				</NextAuthProvider>
			</body>
		</html>
	);
}
