import Link from 'next/link'
import Image from 'next/image'
import Logo from '@/public/images/logo.svg'

const Footer = () => {
	return (
		<footer className="bg-gray-200 py-4">
			<div className="container mx-auto px-4 text-center">
				<p className="text-gray-600">Made by ScorpioAI</p>
			</div>
		</footer>
	);
};

export default Footer;