"use client";
import { SessionProvider } from "next-auth/react";
type Props = {
	children?: React.ReactNode;
};

export const NextAuthProvider = ({ children }: Props) => {
	// get the session from the context
	// provide properties to the session provider
	return (
		<SessionProvider
			refetchInterval={5 * 60} // 5 minutes
		>
			{children}
		</SessionProvider>
	);
};
