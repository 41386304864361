// header.tsx
"use client";
import { useEffect, useState } from "react";
import { auth } from "@/auth";
import UserButton from "./user-button";
import Image from "next/image";
import Logo from "@/public/appliylogo2.png";
import styles from "./header.module.css";
import { Session } from "next-auth";
import { useSession } from "next-auth/react";
import Link from "next/link";

interface HeaderProps {
	darkMode: boolean;
	toggleDarkMode: () => void;
}

export default function Header({
	darkMode,
	toggleDarkMode,
}: Readonly<HeaderProps>) {
	const { data: session, status } = useSession();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		if (session) {
			setLoading(false);
		}
	}, [status, session]);
	return (
		<header
			className={`${styles.container} ${styles.flex} ${styles.itemsCenter} ${styles.justifyBetween} py-4`}
		>
			<div className={styles.logo}>
				<Image src={Logo} alt="Appy.AI Logo" width="190" height="100" />
			</div>
			<div className="flex items-center space-x-4">
				{/* <button
					onClick={toggleDarkMode}
					className="px-4 py-2 rounded bg-gray-800 text-white hover:bg-gray-700 dark:bg-white dark:text-gray-800 dark:hover:bg-gray-200"
				>
					{darkMode ? "Light Mode" : "Dark Mode"}
				</button> */}
				{/* {session && <UserButton session={session} loading={loading} />} */}
			</div>
		</header>
	);
}
